var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"col-12"},[_c('Breadcrumb',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"row mt-3 mb-3"},[_c('div',{staticClass:"col-sm-6 form-inline"},[_c('filter-bar',{model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"text-sm-right"},[(_vm.$can('CREATE', 'POSCHAIN'))?_c('add-label-button',{attrs:{"title":_vm.i18nNewPosChain},on:{"onClick":_vm.openModalToCreate}}):_vm._e(),(_vm.$can('CREATE', 'IMPORTJOB'))?_c('import-button',{on:{"onClick":_vm.showImportModal}}):_vm._e(),(_vm.$can('CREATE', 'EXPORTJOB'))?_c('export-button',{attrs:{"type":"point_of_sale_chain","create-parameters":_vm.createAdditionalParameters}}):_vm._e()],1)])]),_c('div',{staticClass:"card card-pdv"},[_c('PageHeader',{attrs:{"title":_vm.i18nPosChain(2)}}),_c('div',{staticClass:"card-body"},[_c('api-mode-vuetable',{ref:"vuetable",attrs:{"fetch-data":_vm.fetch,"fields":_vm.fields,"per-page":_vm.perPage,"display-selection-records-per-page":true,"additional-parameters":_vm.additionalParameters,"sort-order":_vm.sortOrder},scopedSlots:_vm._u([{key:"flags",fn:function(props){return [_c('count-table-button',{attrs:{"value":props.rowData.flags.length},on:{"onClick":function($event){return _vm.openModalFlags(props.rowData)}}})]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"table-button-container text-right"},[_c('more-table-button',{on:{"onClick":function($event){return _vm.openModalToEdit(props.rowData, false)}}})],1)]}}])})],1)],1)],1),_c('pos-chain-modal',{ref:"posChainModal",on:{"refresh":_vm.reloadTable}}),_c('grid-form-modal',{ref:"gridFlagsModal",attrs:{"modal":_vm.modal,"items":_vm.modal.flags,"fields":_vm.fieldsFlagModal,"title":_vm.getI18nModified({
                    prefix: _vm.i18nKey,
                    suffix: 'TITLES.flag',
                    modifier: 2,
                })}}),_c('import-modal',{ref:"importModal",attrs:{"csv-type":'point_of_sale_chain',"available-types":['point_of_sale_chain', 'point_of_sale_chains_has_flag'],"title":_vm.getI18nModified({
                prefix: 'IMPORT_JOBS',
                suffix: 'TYPES.point_of_sale_chain',
                modifier: 2,
            })}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }