<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <pos-flag-modal
                    ref="posFlagModal"
                    @refresh="reloadFlagOptions"
                />
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nPosChain"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nPosChain"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'POSCHAIN')"
                                :title="getI18n(i18nKey, 'TITLES.chain')"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'POSCHAIN')"
                                :title="getI18n(i18nKey, 'TITLES.chain')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col class="col-sm-12 col-md-4">
                        <b-form-group
                            :label="i18nName"
                            label-for="name-input"
                            class="required label-pdv"
                            :invalid-feedback="i18nChainInvalidNameLabel">
                            <b-form-input
                                id="name-input"
                                v-model.trim="$v.posChainModal.name.$model"
                                class="input-pdv-blue"
                                name="name"
                                type="text"
                                :disabled="!isEdit || submitLoading"
                                :state="validateField('name', 'posChainModal')"
                                autofocus
                                autocomplete="off"
                                @input="clearResponseError('name', 'posChainModal')"
                                @blur="$v.posChainModal.name.$touch"
                            ></b-form-input>
                        </b-form-group>
                        <label class="label-pdv">
                            {{ i18nFlagName }}
                        </label>
                        <a
                            v-if="$can('CREATE', 'POSFLAG') && isEdit"
                            href="javascript: void(0);"
                            class="newFlagModal"
                            :title="i18nNewFlag"
                            @click="showFlagModal"
                        >
                            <i class="fe-plus-circle icon-green ml-1" />
                        </a>
                        <multiSelectWithService
                            :id="'add-flag-input'"
                            ref="flagMultiselect"
                            v-model="flagName"
                            :service="'pos-flags'"
                            :searchable="true"
                            :multiple="false"
                            :disabled="!isEdit || submitLoading"
                        />
                    </b-col>
                    <b-col class="col-sm-12 col-md-8">
                        <data-mode-vuetable
                            ref="relationVuetable"
                            v-model="posChainModal.flags"
                            :fields="fields"
                            :display-delete-button="isEdit"
                            :disabled="submitLoading"
                            @onClickDelete="handleRemoveFlag"
                        />
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isPosChainCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import posFlagModal from '@views/posFlag/posFlagModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import { minLength, required } from 'vuelidate/lib/validators'
import PosChainsService from '@src/services/PosChainsService'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import multiSelectWithService from '@src/components/multiSelectWithService'
import cloneObject from '@utils/clone-object'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        posFlagModal,
        ConfirmationFormModal,
        FeedbackModal,
        CancelButton,
        AddButton,
        SaveButton,
        DataModeVuetable,
        EditLabelButton,
        DeleteLabelButton,
        multiSelectWithService,
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    data() {
        return {
            i18nKey,
            i18nCommon,
            posChainModal: {},
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            flagName: '',
            fields: [
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.flag'),
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
        }
    },
    validations: {
        posChainModal: {
            name: {
                required,
                minLength: minLength(2),
            },
        },
    },
    computed: {
        isPosChainCreated() {
            return this.posChainModal.id > 0
        },
        modalOperation() {
            return this.isPosChainCreated ? 'edited' : 'created'
        },
        i18nTitleModal() {
            if (this.isPosChainCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nPosChain}`
                }
                return this.posChainModal.name
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_chain')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nFlagName() {
            return this.getI18n(i18nKey, 'TITLES.flag')
        },
        i18nNewFlag() {
            return this.getI18n(i18nKey, 'BUTTONS.new_flag')
        },
        i18nChainInvalidNameLabel() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nPosChain() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.chain',
                modifier: 1,
            })
        },
    },
    watch: {
        flagName(value) {
            if (value && value?.id) {
                const index = this.posChainModal.flags.findIndex(find => find.id === value.id);
                if (index < 0) {
                    this.posChainModal.flags.push(value)
                }
                this.flagName = ''
                this.$refs.flagMultiselect.$el.focus()
            }
        }
    },
    methods: {
        showModal(data) {
            this.submitLoading = false
            this.deleteLoading = false
            this.flagName = ''

            if (data) {
                this.isEdit = false
                this.posChainModal = cloneObject(data);
            } else {
                this.isEdit = true
                this.posChainModal = {
                    id: 0,
                    name: '',
                    flags: [],
                };
            }

            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.posChainModal.$touch()
            if (!this.$v.posChainModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        createOrUpdatePosChain() {
            const operation = this.isPosChainCreated
                ? PosChainsService.update(
                      this.posChainModal.id,
                      this.posChainModal
                  )
                : PosChainsService.create(this.posChainModal)

            return operation
                .then((response) => {
                    this.replaceModalData(response.data.data)
                    return response
                })
                .catch((error) => {
                    this.unsuccessfulOperation()
                    return error
                })
        },
        replaceModalData(data) {
            Object.assign(this.posChainModal, data)
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const operation = this.isPosChainCreated ? 'edited' : 'created'

            const response = await this.createOrUpdatePosChain()

            const statusCode = response.status.toString()
            if (statusCode.charAt(0) === '2') {
                if (this.posChainModal.flags) {
                    const syncResponse = await PosChainsService.syncFlags(
                        response.data.data.id,
                        this.posChainModal.flags.map((flag) => flag.id)
                    )
                        .then((response) => response)
                        .catch((err) => err)

                    if (syncResponse.status.toString().charAt(0) !== '2') {
                        return false
                    }
                }

                this.successfulOperation(operation)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }
            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteChain)
        },
        async deleteChain(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.posChainModal.$touch()
            this.deleteLoading = true
            const response = await PosChainsService.delete(this.posChainModal.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nPosChain, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        async handleRemoveFlag(id) {
            const index = this.posChainModal.flags.findIndex(find => find.id === id);

            if (index > -1) {
                this.posChainModal.flags.splice(index, 1);
            }
        },
        successfulOperation(operation) {
            this.$emit('refresh')
            this.resetValidation()
            this.$refs.formModal.hide()
            this.positiveFeedback(this.i18nPosChain, operation)
        },
        unsuccessfulOperation(operation) {
            this.negativeFeedback()
        },
        showFlagModal() {
            this.$nextTick(() => this.$refs.posFlagModal.showModal())
        },
        reloadFlagOptions() {
            this.$nextTick(() => this.$refs.flagMultiselect.refresh())
        },
    },
}
</script>

<style scoped>

</style>
