<template>
    <form-modal
        ref="formModal"
        :title="isPosFlagCreated ? i18nEditPosFlag : i18nNewPosFlag"
        :in-editing="true"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nName"
                            class="required label-pdv"
                            label-for="name-input"
                            :invalid-feedback="i18nFlagInvalidNameLabel"
                        >
                            <b-form-input
                                id="name-input"
                                v-model.trim="$v.posFlagModal.name.$model"
                                class="input-pdv-blue"
                                name="name"
                                type="text"
                                :disabled="submitLoading"
                                :state="validateField('name', 'posFlagModal')"
                                autofocus
                                autocomplete="off"
                                @input="clearResponseError('name', 'posFlagModal')"
                                @blur="$v.posFlagModal.name.$touch"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                v-if="isPosFlagCreated"
                ref="okButton"
                :busy="submitLoading"
                @onClick="handleOk"
            />
            <add-button
                v-else
                ref="okButton"
                :busy="submitLoading"
                @onClick="handleOk"
            />
            <cancel-label-button
                ref="cancelButton"
                :busy="submitLoading"
                @onClick="handleCancel"
            />
        </template>
    </form-modal>
</template>

<script>
import PosFlagsService from '@src/services/PosFlagsService'

import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import { minLength, required } from 'vuelidate/lib/validators'
import CancelLabelButton from '@/src/components/CancelLabelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import cloneObject from '@utils/clone-object'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        CancelLabelButton,
        AddButton,
        SaveButton
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    data() {
        return {
            i18nKey,
            i18nCommon,
            posFlagModal: {},
            submitLoading: false,
        }
    },
    validations: {
        posFlagModal: {
            name: {
                required,
                minLength: minLength(2),
            },
        },
    },
    computed: {
        isPosFlagCreated() {
            return this.posFlagModal.id > 0
        },
        modalOperation() {
            return this.isPosFlagCreated ? 'edited' : 'created'
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nFlagInvalidNameLabel() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nNewPosFlag() {
            return this.getI18n(i18nKey, 'BUTTONS.new_flag')
        },
        i18nEditPosFlag() {
            return `${this.getI18n(i18nCommon, 'edit')} ${this.getI18n(
                i18nKey,
                'TITLES.flag'
            )}`
        },
        i18nPosFlag() {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.flag',
                modifier: 1,
            })
        },
    },
    methods: {
        showModal(data) {
            this.submitLoading = false

            if (data) {
                this.posFlagModal = cloneObject(data);
            } else {
                this.posFlagModal = {
                    id: 0,
                    name: '',
                };
            }

            this.$refs.formModal.show()
            this.resetValidation()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        handleCancel() {
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        handleOk() {
            this.$v.posFlagModal.$touch()
            if (!this.$v.posFlagModal.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit()
            }
        },
        async handleSubmit() {
            const operation = this.isPosFlagCreated
                ? PosFlagsService.update(
                      this.posFlagModal.id,
                      this.posFlagModal
                  )
                : PosFlagsService.create(this.posFlagModal)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nPosFlag, this.modalOperation, 3)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }
            this.submitLoading = false
        },
    },
}
</script>
