import BaseService from '@src/services/BaseService'

class PosChainsService extends BaseService {
  constructor() {
    super('pos-chains')
  }

  syncFlags(id, flags) {
    return this.axios.post(`${this.getUrl()}/${id}/sync-flags`, {
      flags: flags,
    })
  }

  removeAllPos(id, params) {
    this.axios.post(`${this.getUrl()()}/${id}/remove-all-pos`, params)
  }
}

export default new PosChainsService()
