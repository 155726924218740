<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import Layout from '@layouts/main.vue'
import PosChainsService from '@src/services/PosChainsService'
import FilterBar from '@src/components/FilterBar'
import posChainModal from '@views/posChain/posChainModal.vue'
import GridFormModal from '@src/components/GridFormModal.vue'
import pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import importModal from '@views/importJob/importJobModal'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import CountTableButton from '@/src/components/CountTableButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import { debounce } from 'lodash'

const i18nKey = 'POS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        posChainModal,
        GridFormModal,
        FilterBar,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        CountTableButton,
        MoreTableButton
    },
    mixins: [pagination, swalFeedback],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            modal: {
                id: null,
                name: '',
                flags: [],
            },
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nKey, 'TITLES.chain'),
                    sortField: 'name',
                },
                {
                    name: 'flags',
                    title: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.flag',
                        modifier: 2,
                    })
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            fieldsFlagModal: [
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.flag'),
                    sortable: true,
                },
            ],
            filterText: '',
            additionalParameters: {
                with: ['flags'],
            },
        }
    },
    metaInfo() {
        return {
            title: this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.chain',
                modifier: 2,
            }),
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'POS',
                        suffix: 'TITLES.pos',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.chain',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewPosChain() {
            return this.getI18n(i18nKey, 'BUTTONS.new_chain')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return PosChainsService.fetchVuetable(url, params)
        },
        createAdditionalParameters() {
            this.additionalParameters.search = ''
            if (this.filterText && this.filterText.length > 0) {
                this.additionalParameters.search = `name:${this.filterText}`
            }
            return this.additionalParameters
        },
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
        i18nPosChain(modifier) {
            return this.getI18nModified({
                prefix: i18nKey + '.TITLES.chain',
                modifier: modifier,
            })
        },
        showModal(data) {
            this.$nextTick(() => this.$refs.posChainModal.showModal(data))
        },
        openModalToCreate() {
            this.showModal()
        },
        openModalToEdit(data) {
            this.showModal(data)
        },
        openModalFlags(data) {
            this.replaceModalData(data)
            this.$nextTick(() => this.$refs.gridFlagsModal.showModal())
        },
        replaceModalData(data) {
            Object.assign(this.modal, {
                id: data.id,
                name: data.name,
                flags: []
            })

            Object.assign(this.modal.flags, data.flags);
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>
<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'POSCHAIN')"
                            :title="i18nNewPosChain"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="point_of_sale_chain"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nPosChain(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="flags" slot-scope="props">
                            <count-table-button :value="props.rowData.flags.length" @onClick="openModalFlags(props.rowData)" />
                        </template>
                        <template slot="actions" slot-scope="props" class="text-right">
                            <div class="table-button-container text-right">
                                <more-table-button @onClick="openModalToEdit(props.rowData, false)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <pos-chain-modal
            ref="posChainModal"
            @refresh="reloadTable"
        />
        <grid-form-modal
            ref="gridFlagsModal"
            :modal="modal"
            :items="modal.flags"
            :fields="fieldsFlagModal"
            :title="getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.flag',
                        modifier: 2,
                    })"
        />
        <import-modal
            ref="importModal"
            :csv-type="'point_of_sale_chain'"
            :available-types="['point_of_sale_chain', 'point_of_sale_chains_has_flag']"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TYPES.point_of_sale_chain',
                    modifier: 2,
                })
            "
        />
    </Layout>
</template>
